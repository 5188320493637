import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Inspection } from "inspector-common/lib/model";
import { Spinner } from "../../../components";

interface InspectionSelectProps {
    inspections: Inspection[]
    selectedInspectionId: string | null
    onSelect: (inspection: string | null) => void
    isLoading: boolean
}

export default function InspectionSelect(props: InspectionSelectProps) {
    const language = useLanguage();

    if (props.isLoading) {
        return <Spinner />
    }

    return <FormControl fullWidth>
        <InputLabel id="inspection-select">{language.document.title}</InputLabel>
        <Select
            labelId="inspection-select"
            value={props.selectedInspectionId ?? ""}
            label={language.document.title}
            onChange={(event: any) => {
                if (event.target.value) {
                    props.onSelect(event.target.value)
                } else {
                    props.onSelect(null)
                }
            }}
        >
            <MenuItem value={""}>
                {language.document.notAvailable}
            </MenuItem>
            {(props.inspections ?? []).map((inspection: Inspection) => (
                <MenuItem key={inspection.identifier} value={inspection.identifier}>
                    {inspection.name}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}