import { Amplify, Auth } from "aws-amplify";
import { ReactNode, useEffect, useState, createContext, useContext } from "react";
import { Role } from "inspector-common/lib/model";
import { AwsConfigAuth } from "./config";
import client from "inspector-common/lib/api/client";
import Client from "inspector-common/lib/api/client/client";

Amplify.configure({ Auth: AwsConfigAuth });

interface AuthConfig {
    isLoading: boolean;
    isAuthenticated: undefined | boolean;
    username: string;
    userFullName: string;
    role: Role | "";
    accessToken: string;
    idToken: string;
    refreshToken: string;
    userId: string
    companyID: string
}

interface UseAuth {
    resetPasswordConfirmation(email: string, code: string, newPassword: string): Promise<Result>;
    resetPassword(email: string): Promise<Result>;
    config: AuthConfig
    signIn: (username: string, password: string) => Promise<Result>;
    signOut: () => void;
    changePassword: (email: string, oldPassword: string, newPassword: string) => Promise<Result>;
};

const defaultAuthConfig: AuthConfig = {
    isLoading: true,
    isAuthenticated: false,
    role: "",
    username: "",
    userFullName: "",
    accessToken: "",
    idToken: "",
    refreshToken: "",
    userId: "",
    companyID: "",
};

interface Result {
    success: boolean;
    message: string;
}

type Props = {
    children?: ReactNode;
};

const authContext = createContext<UseAuth>({} as UseAuth);

export function ProvideAuth(props: Props) {
    (window as any).React2 = require("react")
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
    const [authConfig, setAuthConfig] = useState<AuthConfig>(defaultAuthConfig);

    if (client instanceof Client) {
        client.configure({ Auth: AwsConfigAuth })
    };
    
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((userResult) => {
                Auth.currentSession().then((sessionResult) => {
                    const firstName = sessionResult.getIdToken().payload["custom:FirstName"];
                    const lastName = sessionResult.getIdToken().payload["custom:LastName"];
                    setAuthConfig({
                        isLoading: false,
                        isAuthenticated: true,
                        username: userResult.username,
                        userFullName: firstName + " " + lastName,
                        role: sessionResult.getIdToken().payload["custom:Role"],
                        accessToken: sessionResult.getAccessToken().getJwtToken(),
                        idToken: sessionResult.getIdToken().getJwtToken(),
                        refreshToken: sessionResult.getRefreshToken().getToken(),
                        userId: userResult.attributes.sub,
                        companyID: sessionResult.getIdToken().payload["custom:Company"]
                    });
                    if (sessionResult.getIdToken().getJwtToken()) {
                        client.setToken(sessionResult.getIdToken().getJwtToken())
                    }
                }).catch((error) => console.error(error))
            })
            .catch((error) => {
                console.error(error)
                setAuthConfig({...defaultAuthConfig, isLoading: false});
            });
    }, [authConfig.isLoading, authConfig.isAuthenticated]);

    const signIn = async (username: string, password: string) => {
        try {
            const result = await Auth.signIn(username, password);
            if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
                return {
                    success: false,
                    message: "CHANGE PASSWORD"
                }
            }            
            setAuthConfig({
                isLoading: false,
                isAuthenticated: true,
                role: result["custom:Role"],
                username: result.username,
                userFullName: result["custom:FirstName"] + " " + result["custom:LastName"],
                accessToken: result.signInUserSession.accessToken,
                idToken: result.signInUserSession.idToken,
                refreshToken: result.signInUserSession.refreshToken,
                userId: result.attributes.sub,
                companyID: result["custom:Company"]
            });
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: "LOGIN FAIL",
            };
        }
    };

    const signOut = async () => {
        try {
            await Auth.signOut();
            setAuthConfig({
                ...defaultAuthConfig,
                isLoading: false,
            })
            return { success: true, message: "" };
        } catch (error) {
            return {
                success: false,
                message: "LOGOUT FAIL",
            };
        }
    };

    const changePassword = async (email: string, oldPassword: string, newPassword: string) => {
        return Auth.signIn(email, oldPassword).then(async (user: any) => {
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                await Auth.completeNewPassword(user, newPassword);
            } else {
                await Auth.changePassword(user, oldPassword, newPassword);
            }
            return await signIn(email, newPassword);

        }).catch((error: any) => {
            if (error.toString().includes("Password not long enough")) {
                return {
                    success: false,
                    message: "INVALID PASSWORD FORMAT"
                }
            }
            return {
                success: false,
                message: "LOGIN FAIL"
            }
        })
    };


  const resetPassword = async (email: string) => {
    try {
      const data = await Auth.forgotPassword(email);
      return {
        success: true,
        message: PASSWORD_RESET_SUCCESS
      };
    } catch (err: any) {
      console.error(err);
      if (
        typeof err === "object" &&
        err !== null &&
        "message" in err &&
        typeof err.message === "string"
      ) {
        return { success: false, message: err.message };
      } else {
        // If the error doesn't have a message property or isn't an object, return a default error message
        return {
          success: false,
          message: PASSWORD_RESET_ERROR
        };
      }
    }
  };

  async function resetPasswordConfirmation(
    email: string,
    code: string,
    newPassword: string
  ): Promise<Result> {
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
      return {
        success: true,
        message: PASSWORD_RESET_SUCCESS
      };
    } catch (err: any) {
      console.error(err);
      if (
        typeof err === "object" &&
        err !== null &&
        "message" in err &&
        typeof err.message === "string"
      ) {
        return { success: false, message: err.message };
      } else {
        // If the error doesn't have a message property or isn't an object, return a default error message
        return {
          success: false,
          message: PASSWORD_RESET_ERROR
        };
      }
    }
  };

    return {
        config: authConfig,
        signIn,
        signOut,
        changePassword,
        resetPassword,
        resetPasswordConfirmation,
    };
};

export function useIsAdmin() {
    const auth = useAuth();
    return auth.config.role !== "" && [Role.ADMIN, Role.APP_ADMIN].includes(auth.config.role);
}

export function useIsAppAdmin() {
    const auth = useAuth();
   
    return auth.config.role === Role.APP_ADMIN;
}

export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";