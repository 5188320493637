import { Container } from "@mui/material";
import { useMatch } from "react-router-dom";
import ImageFromReport from "../components/ImageFromReport";
import { Buffer } from "buffer";

interface ImagePreviewPageProps {
}

export default function ImagePreviewPage(props: ImagePreviewPageProps) {
    const match = useMatch("/image/:assignmentId/:inspectionId/:imageId")
    const searchParams = new URL(window.location.href).searchParams

    const assignmentId = match?.params.assignmentId;
    const inspectionId = match?.params.inspectionId;
    const imageId = match?.params.imageId;
    const paths = searchParams.get("paths") ? JSON.parse(Buffer.from(searchParams.get("paths")!, "base64").toString()) : []
    const rotation = searchParams.get("rotation") ? Number(searchParams.get("rotation")?.replaceAll("/", "")!) : 0

    if (!assignmentId || !inspectionId || !imageId) {
        return <>404: Not found</>
    }

    let url = window.location.origin;
    if (window.location.origin.endsWith(":3001")) {
        url = "https://dev.loci.expert"
    } else if (window.location.origin.endsWith(":3002")) {
        url = "https://loci.expert"
    }

    return <Container sx={{textAlign: "center"}}>
        <ImageFromReport 
            url={`${url}/inspection_images/${assignmentId}/${inspectionId}/${imageId}`}
            imageId={imageId}
            paths={paths}
            rotation={rotation} 
        />
        {/* <img src={`${url}/inspection_images/${assignmentId}/${inspectionId}/${imageId}`} /> */}
    </Container>

}