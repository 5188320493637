import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment } from "inspector-common/lib/model";

interface AssignmentSelectProps {
    assignments: Assignment[]
    selectedAssignmentId: string | null
    onSelect: (assignment: string | null) => void
}

export default function AssignmentSelect(props: AssignmentSelectProps) {
    const language = useLanguage();

    return <FormControl fullWidth>
        <InputLabel id="assignment-select">{language.panels.assignments}</InputLabel>
        <Select
            labelId="assignment-select"
            value={props.selectedAssignmentId ?? ""}
            label={language.document.template}
            onChange={(event: any) => {
                if (event.target.value) {
                    props.onSelect(event.target.value)
                } else {
                    props.onSelect(null)
                }
            }}
        >
            <MenuItem value={""}>
                {language.assignments.name}
            </MenuItem>
            {(props.assignments ?? []).map((assignment: Assignment) => (
                <MenuItem key={assignment.identifier} value={assignment.identifier}>
                    {assignment.name}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}