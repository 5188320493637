import { useListCompanies } from "inspector-common/lib/api/hooks";
import { Company } from "inspector-common/lib/model";
import { useEffect, useState } from "react";

export function useCompanies() {
    const [companies, setCompanies] = useState<Company[]>([]);
    const { execute, isLoading } = useListCompanies();

    async function refresh() {
        return execute({}).then((response) => {
            if (response?.companies) {
                setCompanies(response.companies);
            }
        })
    }

    useEffect(() => {
        refresh()
    }, [])

    return { companies, isLoading, refresh } 
}