import { Button, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { TextFieldContainer } from "../../../../components";
import { Cancel } from "@mui/icons-material";
import { useUserList } from "../../../../state";
import { User } from "inspector-common/lib/model";

interface CollaboratorsInputProps {
    collaborators: string[]
    setCollaborators: (collaborators: string[]) => void
}

const styles = {
    stack: {
        justifyContent: "flex-start",
        alignItems: "center",
        maxWidth: "400px",
        width: "100%"
    },
    select: {
        width: "100%"
    }
}

export default function CollaboratorsInput(props: CollaboratorsInputProps) {
    const language = useLanguage();
    const users = useUserList();

    return <TextFieldContainer>
        <Stack spacing={2} direction={"column"}>
            <Typography>{language.assignments.collaborators}</Typography>
            {
                props.collaborators.map((c, i) => {
                    return <Stack direction={"row"} sx={styles.stack} spacing={2}>
                        <Select
                            value={c}
                            onChange={(event: any) => props.setCollaborators([
                                ...props.collaborators.slice(0, i).filter((a) => a !== event.target.value),
                                event.target.value,
                                ...props.collaborators.slice(i + 1).filter((a) => a !== event.target.value),
                            ])}
                            sx={styles.select}
                        >
                            {(users).map((user: User) => <MenuItem key={user.identifier} value={user.identifier}>
                                {`${user.first_name} ${user.last_name}`}
                            </MenuItem>)}

                        </Select>
                        <IconButton onClick={() => {
                            props.setCollaborators([
                                ...props.collaborators.slice(0, i),
                                ...props.collaborators.slice(i + 1)
                            ])
                        }}>
                            <Cancel />
                        </IconButton>
                    </Stack>
                })
            }
            <Button variant="text" onClick={() => props.setCollaborators(props.collaborators.filter((a) => a!== "").concat(""))}>
                {language.generic.add}
            </Button>
        </Stack>

    </TextFieldContainer>
}