import { useUpdateAssignment } from "inspector-common/lib/api/hooks/assignments/useUpdateAssignment";
import { Assignment, Inspection } from "inspector-common/lib/model"
import UpdateAssignmentButton from "../buttons/UpdateAssignmentButton";
import { AddressInput, AssigneeInput, AssignmentNameInput, PlanDateInput } from "../inputs";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { TextFieldContainer } from "../../../../components";
import { Address } from "inspector-common/lib/model/common/Address";
import ReferenceInput from "../inputs/ReferenceInput";
import AssignmentInfoInput from "../inputs/AssignmentInfoInput";
import CollaboratorsInput from "../inputs/CollaboratorsInput";

interface EditAssignmentInputsProps {
    assignment: Assignment
    setAssignment: (value: Assignment) => void
    inspections: Array<Inspection>
    setInspections: (inspections: Array<Inspection>) => void;
};

function EditAssignmentInputs(props: EditAssignmentInputsProps) {
    const navigate = useNavigate();
    const language = useLanguage();

    const setName = (name: string) => {props.setAssignment({...props.assignment, name})};
    const setAddress = (address: Address) => props.setAssignment({...props.assignment, address});
    const setPlanDate = (plan_date: Date) => props.setAssignment({...props.assignment, plan_date});
    const setAssignee = (assignee: string | null) => props.setAssignment({...props.assignment, assignee});
    const setCollaborators = (collaborators: string[]) => props.setAssignment({...props.assignment, collaborators});
    const setReference = (reference: string) => {props.setAssignment({...props.assignment, reference})};
    const setInfo = (info: string) => props.setAssignment({...props.assignment, info})
    const {isLoading, execute} = useUpdateAssignment();

    return <>
        <TextFieldContainer>
            <Typography variant="h5">{language.assignments.info}</Typography>
        </TextFieldContainer>

        <AssignmentNameInput name={props.assignment.name} setName={setName} />
        <TextFieldContainer>
            <AddressInput address={props.assignment.address} setAddress={setAddress} />
        </TextFieldContainer>
        <TextFieldContainer>
            <PlanDateInput planDate={props.assignment.plan_date} setPlanDate={setPlanDate} />
        </TextFieldContainer>
        <AssigneeInput
            assignee={props.assignment.assignee}
            setAssignee={setAssignee}
            notifyAssignee={false}
            setNotifyAssignee={() => {}}
            allowNotify={false}
        />
        <CollaboratorsInput
            collaborators={props.assignment?.collaborators ?? []}
            setCollaborators={setCollaborators}
        />
        <ReferenceInput reference={props.assignment.reference ?? ""} setReference={setReference} />
        <AssignmentInfoInput info={props.assignment.info ?? ""} setInfo={setInfo} />
        <UpdateAssignmentButton loading={isLoading} onClick={() => {
            execute({
                assignment: props.assignment
            }).then(() => navigate("/"))
        }}/>

    </>
}

export default EditAssignmentInputs;