import { Template } from "inspector-common/lib/model";
import { DeleteDialog, Title } from "../../../../components";
import { Box, Button, Stack, TextField } from "@mui/material";
import { useDeleteTemplate, useUpdateTemplate } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language";
import { useState } from "react";

interface TemplateEditorTitleProps {
    template: Template
    refresh: () => void;
    setTemplate: (template: Template) => void
};

const styles = {
    titleContainer : {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 3,
        alignItems: "center"
    },
    saveButton: {
        color: "white"
    },
    deleteButton: {
        color: "white",
        marginLeft: 1
    }
};

function TemplateEditorTitle(props: TemplateEditorTitleProps) {    
    const { execute: updateTemplate } = useUpdateTemplate();
    const { execute: deleteTemplate } = useDeleteTemplate();
    const language = useLanguage();
    const [openDelete, setOpenDelete] = useState(false);
    const onDelete = () => {
        if (props.template) {
            deleteTemplate({identifier: props.template?.identifier}).then(props.refresh)
        }
    };

    return <Stack direction="row" sx={styles.titleContainer}>
        <DeleteDialog open={openDelete} setOpen={setOpenDelete} delete={onDelete} />
        <TextField 
            sx={{flexGrow: 1, marginRight: 2}}
            label={language.generic.name} 
            value={props.template?.name ?? ""} 
            onChange={(event: any) => props.setTemplate({...props.template, name: event.target.value})}
        />
        {
            !!props.template ? <Box>
                <Button 
                    sx={styles.saveButton} 
                    variant="contained" 
                    onClick={
                        () => {
                            if (props.template) {
                                updateTemplate({template: props.template}).then(props.refresh)
                            }
                        }
                    }>
                    {language.generic.save}
                </Button>
                <Button 
                    sx={styles.deleteButton} 
                    variant="contained"
                    color="error"
                    onClick={
                        () => setOpenDelete(true)
                    }>
                    {language.generic.delete}
                </Button>
            </Box> : <></>
        }
    </Stack>
};

export default TemplateEditorTitle;