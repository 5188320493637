import { Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../../../auth";
import { useGetUsers } from "inspector-common/lib/api/hooks";
import { TextFieldContainer } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import { User } from "inspector-common/lib/model";
import client from "inspector-common/lib/api/client";
import { Cancel } from "@mui/icons-material";

interface AssigneeInputProps {
    assignee: string | null
    setAssignee: (value: string | null) => void
    notifyAssignee: boolean
    setNotifyAssignee: (value: boolean) => void
    allowNotify: boolean
};

const styles = {
    rowStack: {
        alignItems: "center"
    },
    select: {
        width: "100%"
    }
}
function AssigneeInput(props: AssigneeInputProps) {
    const auth = useAuth();
    const { data, execute: getUsers } = useGetUsers();
    const onChange = (event: any) => props.setAssignee(event.target.value);
    const language = useLanguage();

    useEffect(() => {
        if (client.token) {
            getUsers({})}
        }, [getUsers, auth.config.idToken]);

    return <TextFieldContainer>
        <FormControl fullWidth>
            <InputLabel id="assignee-select">{language.assignments.assign}</InputLabel>
            <Stack direction="row">
                <Select
                    labelId="assignee-select"
                    value={props.assignee ?? ""}
                    label={language.assignments.unassigned}
                    onChange={onChange}
                    sx={styles.select}
                >
                    {(data?.users ?? []).map((user: User) => <MenuItem key={user.identifier} value={user.identifier}>
                        {`${user.first_name} ${user.last_name}`}
                    </MenuItem>)}

                </Select>
                <IconButton onClick={() => props.setAssignee(null)}>
                    <Cancel />
                </IconButton>
            </Stack>
            {props.allowNotify ? <Stack direction="row" spacing={2} sx={styles.rowStack}>
                <Checkbox
                    checked={props.notifyAssignee}
                    onChange={() => props.setNotifyAssignee(!props.notifyAssignee)}
                />
                <Typography>{language.assignments.assignmentMessage}</Typography>
            </Stack> : null}
        </FormControl>
    </TextFieldContainer>
};

export default AssigneeInput;