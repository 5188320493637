import { useParams } from "react-router-dom";
import { useGetAssignment } from "inspector-common/lib/api/hooks/assignments/useGetAssignment";
import { Spinner } from "../../../components";
import { Assignment } from "inspector-common/lib/model";
import { EditAssignmentInputs, InspectionsInput } from "../components";
import EditAssignmentTitle from "../components/title/EditAssignmentTitle";
import { useInspections } from "../../../state/inspections";
import { useEffect } from "react";

interface EditAssignmentPageProps {};

function EditAssignmentPage(props: EditAssignmentPageProps) {
    // TODO Move to shared state
    const { assignmentId } = useParams();

    const {
        isLoading,
        error,
        data,
        execute,
        setData
    } = useGetAssignment();

    useEffect(() => {
        if (assignmentId) {
            execute({identifier: assignmentId})
        }
    }, [assignmentId, execute])

    const { isLoading: isLoadingInspections, inspections, setInspections} = useInspections(assignmentId ?? null);
    
    if (error) {
        return <>Error!</>
    }
    if (isLoading || !data?.assignment) {
        return <Spinner />
    } 

    const setAssignmentProp = (value: Assignment) => setData({assignment: value})

    return <>
        <EditAssignmentTitle name={(data?.assignment as Assignment).name} />
        <InspectionsInput
            isLoading={isLoadingInspections}
            inspections={inspections}
            setInspections={setInspections}
            assignmentId={assignmentId ?? ""}
        />

        <EditAssignmentInputs
            assignment={data?.assignment as Assignment}
            setAssignment={setAssignmentProp}
            inspections={inspections}
            setInspections={setInspections}
        />
    </>
};

export default EditAssignmentPage;