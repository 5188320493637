import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, newInspectionNode, Template } from "inspector-common/lib/model";
import { useEffect, useState } from "react";
import TemplateSelect from "../../assignments/components/inputs/InspectionsInput/TemplateSelect";
import RoomSelect from "./RoomSelect";
import { useAssignmentList } from "../../../state";
import AssignmentSelect from "./AssignmentSelect";
import { useInspections } from "../../../state/inspections";
import InspectionSelect from "./InspectionSelect";
import { copyInspectionNodeStructure, copyInspectionNodeStructureWithAttributes } from "inspector-common/lib/utils/inspection";

interface AddInspectionNodeDialogProps {
    templates: Template[]
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
    open: boolean
    setOpen: (open: boolean) => void
}

export default function AddInspectionNodeDialog(props: AddInspectionNodeDialogProps) {
    const language = useLanguage();
    const handleClose = () => props.setOpen(false);
    const [template, setTemplate] = useState<Template | null>(null);
    const [selectedRoom, setSelectedRoom] = useState<InspectionNode | null>(null);
    const [name, setName] = useState<string>("");
    const [copyFrom, setCopyFrom] = useState<"template" | "assignment">("template");
    const [copyType, setCopyType] = useState<"copy" | "copyStructureWithAttributes" | "copyStructure">("copy");

    const [selectedAssignmentId, setSelectedAssignmentId] = useState<string | null>(null);
    const [selectedInspectionId, setSelectedInspectionId] = useState<string | null>(null);

    const assignments = useAssignmentList();
    const {isLoading, inspections} = useInspections(selectedAssignmentId);
    const inspection = inspections.find((i) => i.identifier === selectedInspectionId);

    function addNode() {
        let newNode = newInspectionNode(name, []);

        if ((template || inspection) && selectedRoom) {
            newNode = {...selectedRoom, name}
        }
        props.updateNode({...props.node, children: [...props.node.children, newNode]})
        setSelectedRoom(null)
    };

    useEffect(() => {
        if (selectedRoom?.name) {
            setName(selectedRoom.name)
        }
    }, [selectedRoom?.name])


    return <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
    >
        <DialogTitle id="alert-dialog-title">
            {language.inspection.addRoom}
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={4} alignItems="stretch">
                <TextField 
                    label={language.generic.name} 
                    value={name} 
                    onChange={(event: any) => setName(event.target.value)}
                />
                <Typography>{language.templates.copyFrom}</Typography>

                <FormControl fullWidth>
                    <InputLabel id="copy-type-select">{language.templates.copyFrom}</InputLabel>
                    <Select
                        labelId="copy-type-select"
                        value={copyFrom}
                        label={language.document.template}
                        onChange={(event: any) => {
                            setCopyFrom(event.target.value)
                        }}
                    >
                        <MenuItem value={"template"}>{language.panels.templates}</MenuItem>
                        <MenuItem value={"assignment"}>{language.panels.assignments}</MenuItem>
                    </Select>
                </FormControl>
                {
                    copyFrom === "template" ? <>
                        <TemplateSelect templates={props.templates} template={template} setTemplate={setTemplate} />
                        {
                            template ? <>
                                <RoomSelect rootNode={template.root} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
                            </> : null
                        }
                    </> : <>
                        <AssignmentSelect
                            assignments={assignments}
                            selectedAssignmentId={selectedAssignmentId}
                            onSelect={setSelectedAssignmentId}
                        />
                        {
                            selectedAssignmentId !== null ? <InspectionSelect
                                inspections={inspections}
                                isLoading={isLoading}
                                onSelect={setSelectedInspectionId}
                                selectedInspectionId={selectedInspectionId}
                            /> : null
                        }
                        {
                            inspection ? <>
                                <FormControl fullWidth>
                                    <InputLabel id="copy-type-select">{language.inspection.copy}</InputLabel>
                                    <Select
                                        labelId="copy-type-select"
                                        value={copyType}
                                        label={language.inspection.copy}
                                        onChange={(event: any) => {
                                            setCopyType(event.target.value)
                                        }}
                                    >
                                        <MenuItem value={"copy"}>{language.inspection.copy}</MenuItem>
                                        <MenuItem value={"copyStructure"}>{language.inspection.copyStructure}</MenuItem>
                                        <MenuItem value={"copyStructureWithAttributes"}>{language.inspection.copyStructureWithAttributes}</MenuItem>
                                    </Select>
                                </FormControl>
                                <RoomSelect rootNode={
                                    copyType === "copy" ? (inspection.root_node) : (
                                        copyType === "copyStructureWithAttributes" ? (
                                            copyInspectionNodeStructureWithAttributes(inspection.root_node)
                                        ) : copyInspectionNodeStructure(inspection.root_node)
                                    )} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
                            </> : null
                        }
                    </>
                }
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                addNode(); 
                handleClose();
            }} variant="contained">{language.generic.confirm}</Button>
            <Button onClick={handleClose}>{language.generic.cancel}</Button>
        </DialogActions>
    </Dialog>
}