import { Box, Button } from "@mui/material";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import { useEffect, useRef, useState } from "react";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useLanguage } from "inspector-common/lib/language";
import { useGetInspectionImageDownloadURL, useGetInspectionImageURL } from "inspector-common/lib/api/hooks";
import SingleImageUpload from "./SingleImageUpload";
import { v4 as uuid} from "uuid";

interface ImageUploadProps {
    images: InspectionImage[]
    setImages: (images: InspectionImage[]) => void
    assignmentId: string
    inspectionId: string
};

interface UploadFile {
    id: string
    name: string
    file: File
    done: boolean
    url: string | null
};

export default function ImageUpload(props: ImageUploadProps) {
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [promises, setPromises] = useState<Promise<InspectionImage>[]>([]);
    const select = useRef<any>(null);
    const language = useLanguage();
    const { execute: getImageUploadUrl } = useGetInspectionImageURL()
    const { execute: getImgUrl } = useGetInspectionImageDownloadURL();
    const [draggingFiles, setDraggingFiles] = useState(false);

  async function uploadFile(file: UploadFile) {
    // Mock on local
    if (window.location.href.includes("localhost:3000")) {
        return Promise.resolve()
    }
    if (file.url) {

        return fetch(file.url, {
            method: "PUT",
            body: new Blob([file.file]),
            headers: {"Content-Type": "application/octet-stream"}
        })
    }
};

  function uploadImages(images: FileList | null) {
    if (images) {
        const uploadFiles: UploadFile[] = Array.from(images).map((image) => {
            return {
                id: uuid(),
                name: image.name,
                file: image,
                done: false,
                url: null
            }
        });
        setFiles(uploadFiles);
        getImageUploadUrl({
            assignment_id: props.assignmentId,
            inspection_id: props.inspectionId,
            image_ids: uploadFiles.map((value) => value.id)
        }).then((response) => {
            const urls = response?.urls;
            if (urls) {
                const files = uploadFiles.map((file: UploadFile, index: number) => {
                    return {...file, url: (urls[index].url)}
                });
                setFiles(files);
                setPromises(files.map((file: UploadFile) => uploadFile(file).then(() => {
                    return getImgUrl({assignment_id: props.assignmentId, inspection_id: props.inspectionId, image_ids: [file.id]}).then((response) => {
                        if (response?.urls[0]) {
                            return {identifier: file.id, url: response?.urls[0]};
                        };
                        return null;
                    })
                })) as any);
            }
        })
    }
  }

  useEffect(() => {
    if (promises.length > 0 && files.length > 0) {
        Promise.all(promises).then((images: InspectionImage[]) => {
            props.setImages([...props.images, ...images])
        }).then(() => setFiles([])).then(() => setPromises([]))
    }

  }, [files.map((f) => f.id).join("."), promises.length])

  const handleDragOver = (event: any) => {
    event.preventDefault();
    setDraggingFiles(true);
  }
    
    // Function to handle image drop
    const handleDrop = (event: any) => {
        event.preventDefault();
        setDraggingFiles(false);
        uploadImages(event.dataTransfer.files)
    }
  
  return <Box>
        <Button
            onClick={() => select.current?.click()}
            startIcon={<AddPhotoAlternateIcon />}
            variant={draggingFiles ? "contained" : "outlined"}
            onDragOver={handleDragOver}
            onDragLeave={() => setDraggingFiles(false)}
            onDrop={handleDrop}
        >{language.inspection.addImages}</Button>
        <input
            type="file"
            id="fileUpload"
            accept="image/png, image/jpeg, image/jpg"
            multiple={true}
            ref={select}
            style={{display: "none"}}
            onChange={(event) => {uploadImages(event.target.files)} }
        />
        {
            files.map((file: UploadFile, index: number) => {
                const promise = promises.at(index);

                return <SingleImageUpload
                    key={file.id}
                    assignmentId={props.assignmentId}
                    inspectionId={props.inspectionId}
                    identifier={file.id}
                    name={file.name}
                    imgUrl={file.url}
                    file={file.file}
                    promise={promise}
                />
            })
        }
  </Box>
};
