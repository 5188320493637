import { useEffect, useState } from "react";
import { useAuth } from "../auth";
import { Inspection } from "inspector-common/lib/model";
import { useGetInspections } from "inspector-common/lib/api/hooks";

export function useInspections(assignmentId: string | null) {
    const [inspections, setInspections] = useState<Inspection[]>([]);
    const { isLoading, execute: getInspections } = useGetInspections();
    const auth = useAuth();
    
    useEffect(() => {
        if (assignmentId) {
            getInspections({assignment_id: assignmentId}).then((response) => {
                if (response?.inspections) {
                    setInspections(response.inspections);
                }
            });
        } else {
            setInspections([])
        }

    }, [assignmentId, auth.config.accessToken])

    return {
        isLoading,
        inspections,
        setInspections
    }
}